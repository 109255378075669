
import { initializeTranslations } from './translationService';
import { supabase } from "@/integrations/supabase/client";
import { ALL_TRANSLATION_SECTIONS } from '../components/TranslationProvider';

export const setupTranslations = async () => {
  try {
    // Get preferred language from localStorage or browser settings
    const savedLanguage = localStorage.getItem('preferredLanguage');
    const browserLang = navigator.language.split('-')[0];
    const preferredLang = savedLanguage || browserLang || 'en';

    console.log("Setting up translations system...");
    console.log("Preferred language:", preferredLang);
    
    // Check if we have languages
    const { error: languageError, count: langCount } = await supabase
      .from('languages')
      .select('*', { count: 'exact', head: true });

    console.log("Language count check result:", langCount, languageError ? languageError.message : "No error");

    // If no languages or error, initialize them with multiple retries
    if (languageError || (langCount !== null && langCount < 15)) {
      console.log("Insufficient languages found in database. Initializing languages...");
      
      // Set up retry logic to handle potential service role permission issues
      let success = false;
      let attempts = 0;
      
      while (!success && attempts < 5) {
        attempts++;
        console.log(`Attempt ${attempts} to initialize languages...`);
        
        try {
          // Use the project URL instead of accessing protected properties
          const projectUrl = "https://dwxhsyqcytpjmnoohtpj.supabase.co";
          const url = `${projectUrl}/functions/v1/init-languages`;
          
          console.log("Calling init-languages function at:", url);
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${process.env.SUPABASE_ANON_KEY || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImR3eGhzeXFjeXRwam1ub29odHBqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDAwMjgwOTIsImV4cCI6MjA1NTYwNDA5Mn0.4vYK8WlmrQnL9OIu26QnXlw_eagQFICjPAOsPbm12M4'}`
            },
            body: JSON.stringify({
              forceUpdate: true, // Force update to ensure all languages are available
              withRetries: true  // Enable retries in the edge function
            })
          });
          
          const result = await response.text();
          console.log('Languages initialization response:', response.status, result);
          
          if (response.ok) {
            success = true;
            console.log('Languages initialized successfully');
          } else {
            console.error(`Failed to initialize languages (attempt ${attempts}):`, response.statusText);
          }
        } catch (err) {
          console.error(`Error during language initialization (attempt ${attempts}):`, err);
        }
        
        // Wait before retrying
        if (!success && attempts < 5) {
          await new Promise(resolve => setTimeout(resolve, 2000));
        }
      }
      
      if (!success) {
        console.error("All attempts to initialize languages failed.");
      } else {
        // Force a delay to ensure languages are written to DB
        await new Promise(resolve => setTimeout(resolve, 3000));
      }
    }

    // Check if we have any translations
    const { error: translationError, count: translationCount } = await supabase
      .from('translations')
      .select('*', { count: 'exact', head: true });

    console.log("Translation count check result:", translationCount, translationError ? translationError.message : "No error");

    // If no translations or error, seed them with multiple retries
    // Increased threshold to ensure more translations are created
    if (translationError || (translationCount !== null && translationCount < 800)) {
      console.log("Insufficient translations found in database. Seeding translations...");
      
      // Set up retry logic
      let success = false;
      let attempts = 0;
      
      while (!success && attempts < 5) {
        attempts++;
        console.log(`Attempt ${attempts} to seed translations...`);
        
        try {
          // Use the project URL instead of accessing protected properties
          const projectUrl = "https://dwxhsyqcytpjmnoohtpj.supabase.co";
          const url = `${projectUrl}/functions/v1/seed-translations`;
          
          console.log("Calling seed-translations function at:", url);
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${process.env.SUPABASE_ANON_KEY || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImR3eGhzeXFjeXRwam1ub29odHBqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDAwMjgwOTIsImV4cCI6MjA1NTYwNDA5Mn0.4vYK8WlmrQnL9OIu26QnXlw_eagQFICjPAOsPbm12M4'}`
            },
            body: JSON.stringify({
              // Include all sections that need translations
              sections: ALL_TRANSLATION_SECTIONS,
              forceUpdate: true, // Force update to ensure all translations are complete
              comprehensive: true // Ensure comprehensive translations
            })
          });
          
          const result = await response.text();
          console.log('Translations seeding response:', response.status, result);
          
          if (response.ok) {
            success = true;
            console.log('Translations seeded successfully');
          } else {
            console.error(`Failed to seed translations (attempt ${attempts}):`, response.statusText);
          }
        } catch (err) {
          console.error(`Error during translation seeding (attempt ${attempts}):`, err);
        }
        
        // Wait before retrying with increasing delay
        if (!success && attempts < 5) {
          const delay = attempts * 2000; // Increasing delay with each attempt
          await new Promise(resolve => setTimeout(resolve, delay));
        }
      }
      
      if (!success) {
        console.error("All attempts to seed translations failed.");
      } else {
        // Force a longer delay to ensure translations are written to DB
        await new Promise(resolve => setTimeout(resolve, 5000));
      }
    }
    
    // Always ensure all sections have complete translations with enhanced simulation
    try {
      console.log("Ensuring all sections have complete translations for all languages...");
      
      // Multiple attempts to ensure translations are complete
      let success = false;
      let attempts = 0;
      
      while (!success && attempts < 5) {
        attempts++;
        console.log(`Attempt ${attempts} to complete translations...`);
        
        try {
          const projectUrl = "https://dwxhsyqcytpjmnoohtpj.supabase.co";
          const url = `${projectUrl}/functions/v1/add-translations`;
          
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${process.env.SUPABASE_ANON_KEY || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImR3eGhzeXFjeXRwam1ub29odHBqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDAwMjgwOTIsImV4cCI6MjA1NTYwNDA5Mn0.4vYK8WlmrQnL9OIu26QnXlw_eagQFICjPAOsPbm12M4'}`
            },
            body: JSON.stringify({
              sections: ALL_TRANSLATION_SECTIONS,
              force: true, // Force update all translations to ensure complete coverage
              comprehensive: true, // Ensure comprehensive and accurate translations
              scanWebsite: true // Scan the website for text to translate
            })
          });
          
          if (response.ok) {
            const result = await response.json();
            console.log(`Translation completion successful. Added ${result.translationsAdded || 0} translations.`);
            success = true;
          } else {
            console.error(`Error completing translations (attempt ${attempts}):`, await response.text());
          }
        } catch (err) {
          console.error(`Error during translation completion check (attempt ${attempts}):`, err);
        }
        
        // Wait before retrying with increasing delay
        if (!success && attempts < 5) {
          const delay = attempts * 2500; // Increasing delay with each attempt
          await new Promise(resolve => setTimeout(resolve, delay));
        }
      }
      
      if (!success) {
        console.error("All attempts to complete translations failed.");
      }
    } catch (err) {
      console.error("Error during translation completion process:", err);
    }

    // Extended wait to ensure all DB operations have completed
    await new Promise(resolve => setTimeout(resolve, 5000));
    
    // Initialize translations
    await initializeTranslations(preferredLang);
    
    console.log('Translation system initialized successfully');
    return true;
  } catch (error) {
    console.error('Failed to set up translations:', error);
    console.error("Translation system initialization failed. Using fallbacks.");
    return false;
  }
};
