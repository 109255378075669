
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { 
  translationService, 
  changeLanguage as changeLanguageService, 
  initializeTranslations,
  getCurrentLanguageCode,
  refreshTranslations,
  Language
} from '@/utils/translationService';
import { supabase } from "@/integrations/supabase/client";
import { setupTranslations } from '@/utils/initTranslations';

// Define all website sections that need translations
export const ALL_TRANSLATION_SECTIONS = [
  'common', 
  'navigation', 
  'home', 
  'beats', 
  'pricing', 
  'support',
  'soundProduction', 
  'mixAndMaster',
  'vfx',
  'faq',
  'youtube',
  'howItWorks',
  'notFound',
  'aboutUs',
  'footer',
  'login',
  'registration',
  'profile',
  'cart',
  'checkout',
  'productDetails',
  'reviews',
  'legal',
  'contactForm',
  'settings',
  // Add any other sections used in the website
  'buttons',
  'errors',
  'forms',
  'headers',
  'labels',
  'messages',
  'placeholders',
  'seo',
  'social',
  'meta',
  'breadcrumbs'
];

type TranslationContextType = {
  t: (namespace: string, key: string, defaultValue?: string) => string;
  changeLanguage: (langCode: string) => Promise<void>;
  refreshAllTranslations: () => Promise<void>;
  languages: Language[];
  currentLanguage: Language | null;
  ready: boolean;
};

const TranslationContext = createContext<TranslationContextType>({
  t: (namespace, key, defaultValue) => defaultValue || key,
  changeLanguage: async () => {},
  refreshAllTranslations: async () => {},
  languages: [],
  currentLanguage: null,
  ready: false
});

export const useTranslation = () => useContext(TranslationContext);

export const TranslationProvider = ({ children }: { children: ReactNode }) => {
  const [languages, setLanguages] = useState<Language[]>([]);
  const [currentLanguage, setCurrentLanguage] = useState<Language | null>(null);
  const [ready, setReady] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const MAX_RETRIES = 5;

  // Initialize translations
  useEffect(() => {
    const initialize = async () => {
      try {
        console.log("TranslationProvider: Initializing translation system");
        
        // Check local storage for saved language preference
        const savedLanguage = localStorage.getItem('preferredLanguage');
        console.log("TranslationProvider: Saved language from localStorage:", savedLanguage);
        
        // Set up translations system (initialize languages and base translations)
        await setupTranslations();
        
        // Try to initialize translations directly
        await initializeTranslations(savedLanguage || undefined);
        
        // Get loaded languages
        const langs = translationService.getLanguages();
        console.log("TranslationProvider: Available languages:", langs.length, langs);
        setLanguages(langs);
        
        // Get current language
        const current = translationService.getCurrentLanguage();
        console.log("TranslationProvider: Current language set to:", current?.name, current);
        setCurrentLanguage(current);
        
        // Comprehensive setup of translations for all languages
        await ensureCompleteTranslations();
        
        setReady(true);
        console.log("Translations loaded successfully");
      } catch (error) {
        console.error("Failed to initialize translation system:", error);
        
        // Retry initialization if failed and under retry limit
        if (retryCount < MAX_RETRIES) {
          console.log(`Retrying translation initialization (attempt ${retryCount + 1}/${MAX_RETRIES})...`);
          setRetryCount(prevCount => prevCount + 1);
          // Wait before retrying
          setTimeout(() => initialize(), 2000);
        } else {
          setReady(true); // Set ready even on error so the app doesn't get stuck
          console.error("Max retries reached. Using fallback translation mode.");
        }
      }
    };

    initialize();
  }, [retryCount]);

  // Ensure all sections have complete translations
  const ensureCompleteTranslations = async () => {
    try {
      console.log("TranslationProvider: Ensuring complete translations for all sections");
      
      // Try both methods to ensure we get translations
      
      // Method 1: Using Edge Function
      try {
        console.log("TranslationProvider: Using edge function to fetch all translations");
        const currentLangCode = getCurrentLanguageCode();
        
        // Force refresh translations using our new edge function
        await refreshTranslations();
        
        console.log(`TranslationProvider: Successfully refreshed translations for ${currentLangCode}`);
      } catch (error) {
        console.error("TranslationProvider: Error using edge function:", error);
        
        // Fallback to direct method
        try {
          // Method 2: Using Direct Add Translations function call
          console.log("TranslationProvider: Trying direct HTTP call to add-translations edge function");
          
          const response = await fetch('https://dwxhsyqcytpjmnoohtpj.supabase.co/functions/v1/add-translations', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImR3eGhzeXFjeXRwam1ub29odHBqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDAwMjgwOTIsImV4cCI6MjA1NTYwNDA5Mn0.4vYK8WlmrQnL9OIu26QnXlw_eagQFICjPAOsPbm12M4`
            },
            body: JSON.stringify({
              force: true,
              sections: ALL_TRANSLATION_SECTIONS,
              comprehensive: true,
              scanWebsite: true
            })
          });
          
          if (!response.ok) {
            throw new Error(`HTTP error: ${response.status}`);
          }
          
          const result = await response.json();
          console.log("TranslationProvider: Direct HTTP call result:", result);
        } catch (fallbackError) {
          console.error("TranslationProvider: Both methods failed to add translations:", fallbackError);
        }
      }
      
      // Reload translations for current language to ensure we have the latest translations
      const current = translationService.getCurrentLanguage();
      if (current) {
        await changeLanguageService(current.code);
      }
      
    } catch (error) {
      console.error("TranslationProvider: Error ensuring complete translations:", error);
    }
  };

  // Listen for language changes
  useEffect(() => {
    const handleLanguageChange = () => {
      const current = translationService.getCurrentLanguage();
      setCurrentLanguage(current);
      console.log("TranslationProvider: Language changed to:", current?.name);
    };
    
    window.addEventListener('languagechange', handleLanguageChange);
    
    return () => {
      window.removeEventListener('languagechange', handleLanguageChange);
    };
  }, []);

  // Translation function
  const t = (namespace: string, key: string, defaultValue?: string): string => {
    const translation = translationService.getTranslation(namespace, key, defaultValue);
    return translation || defaultValue || key;
  };

  // Change language function with page reload for full refresh
  const changeLanguageFn = async (langCode: string): Promise<void> => {
    console.log("TranslationContext: Changing language to:", langCode);
    try {
      // Save preference to localStorage first
      localStorage.setItem('preferredLanguage', langCode);
      
      await changeLanguageService(langCode);
      
      // Set current language
      const current = translationService.getCurrentLanguage();
      setCurrentLanguage(current);
      
      // Force a re-render of all components
      window.dispatchEvent(new Event('languagechange'));
      
      console.log(`Language changed to ${current?.name || langCode}. Reloading...`);
      
      // Reload the page immediately to ensure complete site translation
      setTimeout(() => {
        window.location.reload();
      }, 100); // Reduced to 100ms for faster reload
    } catch (err) {
      console.error("TranslationContext: Failed to change language:", err);
      throw err;
    }
  };
  
  // Function to force refresh all translations
  const refreshAllTranslationsFn = async (): Promise<void> => {
    try {
      console.log("TranslationContext: Forcing refresh of all translations");
      await refreshTranslations();
      console.log("TranslationContext: Successfully refreshed all translations");
      
      // Force a re-render of all components
      window.dispatchEvent(new Event('languagechange'));
    } catch (err) {
      console.error("TranslationContext: Failed to refresh translations:", err);
      throw err;
    }
  };

  return (
    <TranslationContext.Provider 
      value={{ 
        t, 
        changeLanguage: changeLanguageFn, 
        refreshAllTranslations: refreshAllTranslationsFn,
        languages, 
        currentLanguage,
        ready 
      }}
    >
      {children}
    </TranslationContext.Provider>
  );
};
