
import { supabase } from "@/integrations/supabase/client";

export type Language = {
  id: number;
  code: string;
  name: string;
  country_code: string;
  country_name: string;
  is_default: boolean;
};

export type TranslationMap = {
  [namespace: string]: {
    [key: string]: string;
  };
};

// Singleton to store loaded translations
class TranslationCache {
  private static instance: TranslationCache;
  private languages: Language[] = [];
  private translations: { [langCode: string]: TranslationMap } = {};
  private currentLanguage: Language | null = null;
  private isLoading: boolean = false;
  private loadPromises: { [key: string]: Promise<any> } = {};
  private debugMode: boolean = true; // Enable debug logging

  private constructor() {}

  public static getInstance(): TranslationCache {
    if (!TranslationCache.instance) {
      TranslationCache.instance = new TranslationCache();
    }
    return TranslationCache.instance;
  }

  async loadLanguages(): Promise<Language[]> {
    if (this.languages.length > 0) return this.languages;
    
    if (!this.loadPromises['languages']) {
      this.debug('Loading languages from Supabase...');
      this.loadPromises['languages'] = Promise.resolve(
        supabase
          .from('languages')
          .select('*')
          .order('is_default', { ascending: false })
          .order('name', { ascending: true })
          .then(({ data, error }) => {
            if (error) {
              console.error('Error loading languages:', error);
              return [];
            }
            
            this.languages = data as Language[];
            this.debug(`Loaded ${this.languages.length} languages`, this.languages);
            return this.languages;
          })
      ).then(
        (result) => {
          delete this.loadPromises['languages'];
          return result;
        },
        (error) => {
          delete this.loadPromises['languages'];
          throw error;
        }
      );
    }
    
    return this.loadPromises['languages'];
  }

  getLanguages(): Language[] {
    return this.languages;
  }

  async setCurrentLanguage(langCode: string): Promise<void> {
    this.debug(`Setting current language to: ${langCode}`);
    const languages = await this.loadLanguages();
    
    if (languages.length === 0) {
      console.error('No languages available in database. Cannot set language.');
      return;
    }
    
    // First try exact match on code
    let language = languages.find(lang => lang.code === langCode);
    
    // If not found, try match by country code
    if (!language) {
      language = languages.find(lang => lang.country_code === langCode);
      this.debug(`No exact code match, trying country_code match: ${language?.code}`);
    }
    
    // If not found, try partial match on code
    if (!language) {
      language = languages.find(lang => 
        langCode.startsWith(lang.code) || 
        lang.code.startsWith(langCode)
      );
      this.debug(`No country_code match, trying partial match: ${language?.code}`);
    }
    
    // If still not found, use default language
    if (!language) {
      language = languages.find(lang => lang.is_default) || languages[0];
      this.debug(`Language ${langCode} not found, using default: ${language?.code}`);
    }
                     
    if (language) {
      this.currentLanguage = language;
      this.debug(`Current language set to: ${language.name} (${language.code})`);
      
      // Always load translations for English as fallback
      if (language.code !== 'en') {
        const enLang = languages.find(lang => lang.code === 'en');
        if (enLang) {
          this.debug('Loading English translations as fallback');
          await this.loadTranslationsForLanguage('en');
        }
      }
      
      // Load translations for the selected language
      await this.loadTranslationsForLanguage(language.code);
      
      // Save to localStorage for persistence
      localStorage.setItem('preferredLanguage', language.code);
      this.debug(`Language preference saved to localStorage: ${language.code}`);
    } else {
      console.error('Failed to set language. No matching language found and no default available.');
    }
  }

  getCurrentLanguage(): Language | null {
    return this.currentLanguage;
  }

  async loadTranslationsForLanguage(langCode: string): Promise<TranslationMap> {
    this.debug(`Loading translations for language: ${langCode}`);
    
    // If translations are already cached, return them
    if (this.translations[langCode]) {
      this.debug(`Using cached translations for ${langCode}`);
      return this.translations[langCode];
    }
    
    const cacheKey = `translations_${langCode}`;
    if (!this.loadPromises[cacheKey]) {
      this.isLoading = true;
      
      try {
        // Try to load translations using the edge function first (recommended approach)
        this.debug(`Attempting to fetch translations using edge function for ${langCode}`);
        
        this.loadPromises[cacheKey] = Promise.resolve(
          supabase.functions
            .invoke('fetch-all-translations', {
              method: 'POST',
              body: { languageCode: langCode }
            })
            .then(async ({ data, error }) => {
              if (error) {
                this.debug(`Error using edge function: ${error.message}, falling back to direct query`);
                throw error; // Throw to trigger fallback
              }
              
              if (!data || !data.translations) {
                this.debug('Invalid response from edge function, falling back to direct query');
                throw new Error('Invalid response from edge function');
              }
              
              this.debug(`Successfully loaded translations via edge function for ${langCode}`);
              this.translations[langCode] = data.translations;
              return data.translations;
            })
            .catch(async (error) => {
              // Fallback to direct database query if edge function fails
              this.debug(`Falling back to direct DB query for ${langCode}: ${error.message}`);
              
              // First get language id
              const { data: languageData, error: languageError } = await supabase
                .from('languages')
                .select('id')
                .eq('code', langCode)
                .single();
                
              if (languageError) {
                console.error(`Error finding language ${langCode}:`, languageError);
                return {};
              }
              
              const languageId = languageData.id;
              this.debug(`Found language ID for ${langCode}: ${languageId}`);
              
              // Now get all translations for this language
              const { data: translationsData, error: translationsError } = await supabase
                .from('translations')
                .select(`
                  key_id,
                  value,
                  translation_keys!inner (
                    key,
                    namespace:translation_namespaces!inner (
                      namespace
                    )
                  )
                `)
                .eq('language_id', languageId);

              if (translationsError) {
                console.error(`Error loading translations for ${langCode}:`, translationsError);
                return {};
              }
              
              this.debug(`Loaded ${translationsData?.length || 0} translations for ${langCode}`);

              // Structure the translations by namespace and key
              const translations: TranslationMap = {};
              
              if (translationsData && translationsData.length > 0) {
                translationsData.forEach((translation: any) => {
                  try {
                    const namespace = translation.translation_keys.namespace.namespace;
                    const key = translation.translation_keys.key;
                    const value = translation.value;
                    
                    if (!translations[namespace]) {
                      translations[namespace] = {};
                    }
                    
                    translations[namespace][key] = value;
                  } catch (error) {
                    console.error('Error processing translation:', translation, error);
                  }
                });
                
                this.debug(`Processed translations for ${langCode}, namespaces:`, Object.keys(translations));
              } else {
                console.warn(`No translations found for language ${langCode}`);
                // Create empty structure for the language to avoid repeated database calls
                if (!translations._status) {
                  translations._status = {};
                }
                translations._status.empty = "true"; // Fix: Changed from boolean to string
              }
              
              // Cache the translations
              this.translations[langCode] = translations;
              
              return translations;
            })
        ).then(
          (result) => {
            this.isLoading = false;
            delete this.loadPromises[cacheKey];
            return result;
          },
          (error) => {
            this.isLoading = false;
            delete this.loadPromises[cacheKey];
            throw error;
          }
        );
        
        return this.loadPromises[cacheKey];
      } catch (error) {
        this.isLoading = false;
        delete this.loadPromises[cacheKey];
        console.error(`Failed to load translations for ${langCode}:`, error);
        return {};
      }
    }
    
    return this.loadPromises[cacheKey];
  }

  getTranslation(namespace: string, key: string, defaultValue?: string): string {
    if (!this.currentLanguage) {
      return defaultValue || key;
    }
    
    const langCode = this.currentLanguage.code;
    const translations = this.translations[langCode] || {};
    const namespaceTranslations = translations[namespace] || {};
    
    // If translation exists, return it
    if (namespaceTranslations[key]) {
      return namespaceTranslations[key];
    }
    
    // If we're not using English and translation is missing, try fallback to English
    if (langCode !== 'en' && this.translations['en']) {
      const enNamespaceTranslations = this.translations['en'][namespace] || {};
      if (enNamespaceTranslations[key]) {
        this.debug(`Missing translation for ${langCode}.${namespace}.${key}, using English fallback`);
        return enNamespaceTranslations[key];
      }
    }
    
    // Last resort, return default value or key itself
    return defaultValue || key;
  }

  isLanguageLoading(): boolean {
    return this.isLoading;
  }
  
  // Helper method for consistent debug logging
  private debug(message: string, ...args: any[]) {
    if (this.debugMode) {
      console.log(`[TranslationCache] ${message}`, ...args);
    }
  }
  
  // Method to force refresh translations from the server
  async refreshTranslations(langCode: string): Promise<TranslationMap> {
    this.debug(`Forcing refresh of translations for language: ${langCode}`);
    
    // Remove from cache to force reload
    delete this.translations[langCode];
    
    return this.loadTranslationsForLanguage(langCode);
  }
}

// Export a singleton instance
export const translationService = TranslationCache.getInstance();

// Helper function to get a translation
export function t(namespace: string, key: string, defaultValue?: string): string {
  return translationService.getTranslation(namespace, key, defaultValue);
}

// Get current language code
export function getCurrentLanguageCode(): string {
  return translationService.getCurrentLanguage()?.code || 'en';
}

// Initialize translations - preload English by default
export const initializeTranslations = async (preferredLangCode?: string): Promise<void> => {
  try {
    console.log(`Initializing translations with preferred language: ${preferredLangCode || 'auto'}`);
    await translationService.loadLanguages();
    
    // Use browser language if preferred lang is not specified
    if (!preferredLangCode) {
      const browserLang = navigator.language.split('-')[0];
      preferredLangCode = browserLang || 'en';
      console.log(`Using browser language: ${preferredLangCode}`);
    }
    
    // Set the language (will load translations)
    await translationService.setCurrentLanguage(preferredLangCode);
    console.log(`Translations initialized with language: ${preferredLangCode}`);
    
  } catch (error) {
    console.error('Failed to initialize translations:', error);
    // Fallback to empty translations - app won't crash
  }
};

// Function to change language
export const changeLanguage = async (langCode: string): Promise<void> => {
  console.log(`Changing language to: ${langCode}`);
  await translationService.setCurrentLanguage(langCode);
  // Save language preference to local storage
  localStorage.setItem('preferredLanguage', langCode);
  // Force a re-render of components using translations
  window.dispatchEvent(new Event('languagechange'));
  console.log(`Language changed and saved: ${langCode}`);
};

// Function to force refresh translations for the current language
export const refreshTranslations = async (): Promise<void> => {
  const currentLang = translationService.getCurrentLanguage();
  if (currentLang) {
    await translationService.refreshTranslations(currentLang.code);
    window.dispatchEvent(new Event('languagechange'));
    console.log(`Translations refreshed for: ${currentLang.code}`);
  }
};
